import { Link } from 'react-router-dom';

function Footer() {
    return (
      <footer>
        <p>My Wanted © 2024</p>
        <Link to="/terms-and-privacy" className="text-decoration-none">Conditions Générales & Politique de Confidentialité</Link>
      </footer>
    );
  }
  
  export default Footer;
  