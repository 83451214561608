import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Question from './components/Question';
import Result from './components/Result';
import Footer from './components/Footer';
import TermsAndPrivacy from './components/TermsAndPrivacy';
import './App.css';
import questions from './questions.json';
import MyDropzone from './components/MyDropzone';


function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState('');
  const [showGenderQuestion, setShowGenderQuestion] = useState(false);
  const [showUploadInterface, setShowUploadInterface] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const handleAnswerSelect = (points) => {
    setScore(score + points);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowUploadInterface(true);
    }
  };

  const startQuiz = (e) => {
    e.preventDefault();
    if (username) {
      setShowGenderQuestion(true);
      setShowHome(false);
    }
  };

  const restartQuiz = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
    setShowHome(true);
    setShowGenderQuestion(false);
    setShowUploadInterface(false);
  };

  const beginQuiz = () => {
    setShowGenderQuestion(false);
    setCurrentQuestion(0);
    setScore(0);
    setShowResult(false);
  };

  const handleImageUpload = (dataUrl) => {
    setUploadedImage(dataUrl); // Mettre à jour l'état avec l'URL de l'image
    handleShowResult(); // Passer à l'affichage des résultats
  };
  
  const handleShowResult = () => {
    setShowUploadInterface(false);
    setShowResult(true);
  };
  
  

  return (
    <Router>
      <div className="App">
        <Header />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8427202255526787"
        crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-8427202255526787"
            data-ad-slot="2103197739"
            data-ad-format="auto"
            data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        <Routes>
          <Route path="/" element={
            <>
              {showHome && (
                <div className="home text-center mt-5">
                  <h2 className="mb-4">Bienvenue dans le Quiz pour connaître votre prime</h2>
                  <form onSubmit={startQuiz}>
                    <input
                      type="text"
                      className="form-control mb-3 mx-auto"
                      placeholder="Pseudo"
                      style={{ maxWidth: '300px' }}
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <button
                      type="submit"
                      className="btn btn-lg btn-warning fw-bold text-uppercase text-dark"
                      disabled={!username}
                    >
                      Démarrer le Quiz
                    </button>
                  </form>
                </div>
              )}
              {showGenderQuestion && (
                <div className="gender-selection text-center mt-5">
                  <h2 className="mb-4">Sélectionnez votre genre</h2>
                  <div className='gender-container'>
                    <button onClick={() => setGender('Homme')} className="btn btn-primary me-2">Homme</button>
                    <button onClick={() => setGender('Femme')} className="btn btn-primary me-2">Femme</button>
                    <button onClick={() => setGender('Autre')} className="btn btn-primary me-2">Autre</button>
                    <button onClick={beginQuiz} className="btn btn-lg btn-warning fw-bold text-uppercase text-dark start-quiz" disabled={!gender}>
                      Démarrer le Quiz
                    </button>
                  </div>
                </div>
              )}
              {showUploadInterface && (
                <div className="upload-interface text-center mt-5">
                  <h2 className="mb-4">Téléchargez votre photo pour l'avis de recherche</h2>
                  <MyDropzone onFileAccepted={handleImageUpload} goToNextPage={handleShowResult} />
                  <div className="mt-3">
                    <button onClick={() => { setShowUploadInterface(false); setShowResult(true); }} className="btn btn-lg btn-primary fw-bold text-uppercase mx-2">Afficher le résultat</button>
                    <button onClick={() => { setShowUploadInterface(false); setShowResult(true); }} className="btn btn-lg btn-secondary fw-bold text-uppercase">Passer</button>
                  </div>
                </div>
              )}

              {showResult && (
                <Result score={score} onRestart={restartQuiz} username={username} gender={gender} uploadedImage={uploadedImage} />
              )}
              {!showResult && !showUploadInterface && !showGenderQuestion && !showHome && (
                <Question
                  question={questions[currentQuestion].question}
                  answers={questions[currentQuestion].answers}
                  onSelectAnswer={handleAnswerSelect}
                />
              )}
            </>
          } />
          <Route path="/terms-and-privacy" element={<TermsAndPrivacy />} />
        </Routes>
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8427202255526787"
        crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-8427202255526787"
        data-ad-slot="1230568375"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
        <script>
            (adsbygoogle = window.adsbygoogle || []).push({});
        </script>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
