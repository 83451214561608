import React from 'react';
import html2canvas from 'html2canvas';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from 'react-share';
import wantedBackground from '../img/IMG_8984.png';


function Result({ score, onRestart, username, gender, uploadedImage }) {
  // Calcul du score final avec un arrondi aux 3 derniers chiffres égaux à 0
  const scoreMultiplier = 1000;
  const adjustedScore = Math.max(score * scoreMultiplier, 50000);
  const randomFactor = Math.floor(Math.random() * 10000);
  let finalScore = Math.min(adjustedScore + randomFactor, 500000);
  finalScore = Math.round(finalScore / 1000) * 1000;

  // Fonction pour capturer et télécharger l'image
  const captureAndDownloadImage = () => {
    const captureElement = document.getElementById('wanted-poster');
    html2canvas(captureElement, { useCORS: true }).then((canvas) => {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'wanted-poster.png';
      link.click();
    });
  };
  

  return (
    <div className="wanted-container text-center my-4" >
      <div className="wanted-paper text-center my-4" id="wanted-poster">
          {/* Image de fond Wanted */}
        <img src={wantedBackground} alt="Wanted Poster" className="wanted-background" />

        {/* Image téléchargée ou par défaut */}
        <img src={uploadedImage || "/path/to/default-image.png"} alt="Wanted" className="uploaded-image" style={{ maxWidth: '200px', maxHeight: '200px' }} />

        {/* Texte pour le nom et la prime */}
        <div className="wanted-text">
          <h1>{username.toUpperCase()}</h1>
          <p className="fs-4">{finalScore.toLocaleString()} - Berries</p>
        </div>
      </div>

      {/* Boutons d'action */}
      <button className="btn btn-success" onClick={onRestart}>Recommencer</button>
      <button className="btn btn-info" onClick={captureAndDownloadImage}>Télécharger l'image</button>

      {/* Boutons de partage social */}
      <div className="share-buttons mt-3">
        <FacebookShareButton url={window.location.href} quote={`Je suis recherché(e) pour ${finalScore.toLocaleString()} Berries dans le One Piece Quiz!`}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={window.location.href} title={`Je suis recherché(e) pour ${finalScore.toLocaleString()} Berries dans le One Piece Quiz!`}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
    </div>
  );
}

export default Result;
