import React from 'react';
import { useDropzone } from 'react-dropzone';

function MyDropzone({ onFileAccepted, goToNextPage }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png image/webp',
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
        const reader = new FileReader();
        reader.onload = () => {
          const dataUrl = reader.result;
          onFileAccepted(dataUrl); // Envoyer l'URL de données à la fonction parente
        };
        reader.readAsDataURL(acceptedFiles[0]);
      }
    });

  return (
    <div className="dropzone-container">
        <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {
            isDragActive ?
            <p>Lâchez les fichiers ici...</p> :
            <p>Glissez-déposez des fichiers ici, ou cliquez pour sélectionner des fichiers (PNG ou JPG uniquement)</p>
        }
        </div>
    </div>
  );
}

export default MyDropzone;
