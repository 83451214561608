function Question({ question, answers, onSelectAnswer }) {
  return (
    <div className="card my-4">
      <div className="card-body">
        <h2 className="card-title">{question}</h2>
        <div className="d-grid gap-2">
          {answers.map((answer, index) => (
            <button key={index} className="btn btn-primary" onClick={() => onSelectAnswer(answer.points)}>
              {answer.option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

  export default Question;
  