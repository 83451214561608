import React from 'react';

function TermsAndPrivacy() {
  return (
    <div className="terms-privacy-container">
      <div class="container mt-5">
  <div class="row">
    <div class="col-lg-8 mx-auto">
      
      {/* <!-- Conditions Générales --> */}
      <section class="mb-5">
        <h2 class="h4 mb-3">Conditions Générales</h2>
        <p class="text-muted">
          En accédant et en utilisant [Nom de votre site ou application], vous acceptez d'être lié par ces conditions générales d'utilisation. Toutes les activités sur ce site sont sujettes à ces conditions. Les utilisateurs ne doivent pas utiliser le site de manière malveillante, par exemple en introduisant des virus ou en tentant de gagner un accès non autorisé. Le contenu fourni sur [Nom de votre site ou application] est à des fins d'information générale seulement et est sujet à changement sans préavis.
        </p>
      </section>

      {/* <!-- Politique de Confidentialité --> */}
      <section class="mb-5">
        <h2 class="h4 mb-3">Politique de Confidentialité</h2>
        <p class="text-muted">
          [Nom de votre site ou application] s'engage à protéger votre vie privée. Les informations personnelles collectées, comme votre nom et votre adresse e-mail, le sont uniquement lorsque vous les fournissez volontairement, par exemple lors de l'inscription ou de la prise de contact. Nous utilisons ces informations uniquement pour le but spécifié au moment de la collecte et ne les partageons pas avec des tiers sans votre consentement explicite, sauf si la loi l'exige. Vous avez le droit d'accéder à vos informations personnelles et de demander leur correction ou suppression.
        </p>
      </section>

      {/* <!-- Utilisation des Cookies --> */}
      <section class="mb-5">
        <h2 class="h4 mb-3">Utilisation des Cookies</h2>
        <p class="text-muted">
          [Nom de votre site ou application] utilise des cookies pour améliorer l'expérience utilisateur en mémorisant vos préférences et visites répétées. En cliquant sur "Accepter", vous consentez à l'utilisation de TOUS les cookies. Cependant, vous pouvez visiter les paramètres des cookies pour fournir un consentement contrôlé. Les cookies sont de petits fichiers stockés sur votre dispositif par le site web. Ils sont utilisés pour comprendre comment vous interagissez avec le site et pour améliorer votre expérience globale. Les cookies ne contiennent pas d'informations personnelles identifiables.
        </p>
      </section>

    </div>
  </div>
</div>

    </div>
  );
}

export default TermsAndPrivacy;
